<template>
	<div class="peport-list">		
		<van-pull-refresh v-if=" list.length == 0 " v-model="refreshing" @refresh="onRefresh" style="position:absolute;inset:0;">	
			<zk-no-data :value="true">当前尚未查询到报告记录～</zk-no-data>		
		</van-pull-refresh>
		
		<van-pull-refresh v-if=" list.length "  v-model="refreshing" @refresh="onRefresh" class="van-pull-refresh">		
			<van-list v-model="loading" :finished="finished" finished-text="没有更多了"  @load="onLoad" style="padding: 15px;">	
					
				<div class="peport-list-item" v-for="(item,i) in list" :key="i"  @click="getoRouter(item)">
					<ul class="ul-1">
						<li>
							<span v-if="item.appVersion=='1'">中医四诊1.0报告</span>
							<span v-if="item.appVersion=='2'">中医四诊2.0报告</span>
							<span v-if="item.appVersion=='3'">便携四诊报告</span>
						</li>
						<li style="margin-left: auto;color: #2D6FDF;font-size: 12px;">查看详情<i class="icon-gengdu-copy"></i></li>
					</ul>
					<ul class="ul-2">
						<li class="li-1">机构名称</li>
						<li class="li-2">{{ item.orgName }}</li>
					</ul>
					<ul class="ul-2">
						<li class="li-1">报告时间</li>
						<li class="li-2">{{ item.generateTime }}</li>
					</ul>
				</div>		
			
			</van-list>			
		</van-pull-refresh>
		
	</div>
</template>
<script>
	import { List , PullRefresh } from "vant";
	import { reportlist } from "@api/report";
	import moment from "moment";

	export default {
		name: "reportlist",
		data() {
			return {
				list: [
					
				],
				loading: false,				
				finished: true,           // 显示更多
				refreshing: false,
				value: "",
				queryParams: {
					pageIndex: 1,
					pageSize: 10,
					startDate: "",
					endDate: "",
				},
				pageload: false,
				isShowimg: false,
				show: false,
				minDate: new Date(2022, 1, 1),
			};
		},
		components: {
			[List.name]: List,
			[PullRefresh.name]: PullRefresh,
		},
		mounted() {
			this.onRefresh();
			// window.addEventListener("scroll", this.showbtn, true);
		},
		methods: {
			moment,
			
			//获取报告列表
			onLoad() {
				this.ajax_getlist();
			},
			
			onRefresh() {
				this.queryParams.pageIndex = 1;
				this.finished = false;
				this.refreshing = false;
				this.list = [];  // 清空列表数据				
				this.ajax_getlist();
			},
			
			getoRouter(item){
				if(item.appVersion=='3'){
					this.$router.push({
						name:'reportPortable',
						params:{
							id:item.id || "",
						},
					})
				}else{
					this.$router.push({
						name:'reportdetail',
						params:{
							id:item.id ||"",
						},
					})
				}				
			},
			
			// 获取列表
			ajax_getlist() {
				this.pageload = true;
				reportlist(this.queryParams).then((res) => {
					if (res.respCode == 200) {
						this.list = [...this.list, ...res.result.dataList];
						this.pageload = false;
						this.loading = false;
						if (res.result.pageIndex == res.result.sumPage) {
							this.finished = true;
						} else {
							this.queryParams.pageIndex++;
						}
					} else {
						this.$toast(res.respMsg);
					}
				});
			}
			
			
			
		},
	};
</script>
<style lang="less" scope>
	

	.peport-list{
		position: fixed;inset:0;overflow: auto;background: #f4f4f4;
	}
	.van-pull-refresh{position: relative;}
	
	.peport-list-item{
		border-radius: 10px;background: #fff;padding: 12px;margin-bottom: 16px;
		.ul-1{
			display: flex;border-bottom: solid 1px #ddd;line-height: 30px;height:36px;margin-bottom: 10px;padding:0 2px;color: #202020;font-size: 15px;
		}
		.ul-2{
			display: flex;line-height:28px;padding:0 3px;font-size: 14px;
			.li-1{width: 100px;color: #777;}
			.li-2{flex: 1;color: #414141;}			
		}
	}
	
	.icon-gengdu-copy{font-size: 12px;}
	
	
</style>
